export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  {
    title: "Report",
    icon: "TrelloIcon",
    children: [
      {
        title: "Skill wise candidate report",
        route: "report-skill-wise-candidate",
      },
      {
        title: "Company wise job placement",
        route: "report-company-wise-job-placement",
      },
    ],
  },
  {
    title: "Candidate",
    icon: "UsersIcon",
    children: [
      {
        title: "Candidate List",
        route: "candidates",
      },
    ],
  },
  {
    title: "Company",
    icon: "BoxIcon",
    children: [
      {
        title: "Company",
        route: "company",
      },
    ],
  },
  {
    title: "Company Jobs",
    icon: "BriefcaseIcon",
    children: [
      {
        title: "Create Job",
        route: "create-job",
      },
      {
        title: "View Jobs",
        route: "job-list",
      },
      {
        title: "Job Proposed List",
        route: "proposedCandidate",
      },
      {
        title: "Schedule",
        route: "schedule",
        icon: "BoxIcon",
      },
    ],
  },
  {
    title: "Settings",
    icon: "SettingsIcon",
    children: [
      {
        title: "Role Manage",
        route: "role",
      },
      {
        title: "Industry",
        route: "industry",
      },
      {
        title: "Link Type",
        route: "link-type",
      },
      {
        title: "Skills Manage",
        route: "skills",
      },
      {
        title: "Education Level",
        route: "education-level",
      },
      {
        title: "Board",
        route: "board",
      },
      {
        title: "Subject",
        route: "subject",
      },
      {
        title: "Course",
        route: "course",
      },
      {
        title: "Division",
        route: "division",
      },
      {
        title: "District",
        route: "district",
      },
      {
        title: "Upazila",
        route: "upazila",
      },
      {
        title: "User",
        route: "user",
      },
    ],
  },
];
